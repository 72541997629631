import React, { useRef, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import './style.sass';

function Image({
  urls,
}) {
  const galleryEl = useRef(null);

  useEffect(() => {
    // galleryEl.current.fullscreen();
    if (galleryEl.current) {
      console.log(galleryEl.current);
      galleryEl.current.toggleFullScreen();
    }
  }, []);

  const images = urls.map((url) => ({
    original: url,
  }));

  return (
    <ImageGallery
      ref={galleryEl}
      items={images}
      showNav={images.length > 1}
      showPlayButton={images.length > 1}
      showFullscreenButton={true}
      useBrowserFullscreen={false}
      showBullets={images.length > 1}
    />
  );
}

export default Image;
