import './styles.sass';

function MainFrame({
  url,
  title,
}) {
  return (
    <div className="main-frame">
      <iframe
        title={title}
        src={url}
        allow='camera *;microphone *'
      ></iframe>
    </div>
  );
}

export default MainFrame;