import './styles.sass';

function PlaylistItem({
  id,
  name,
  url,
}) {
  return (
    <div className="playlist-item">
      <a className="link" href={url}>
        <span className="item-title">
          {name}
        </span>
      </a>
    </div>
  );
}

function Playlist({
  name,
  items = [],
}) {
  if (items.length === 0) {
    return (
      <div className="message">
        No items
      </div>
    );
  }

  return (
    <div className="playlist">
      <div className="title">
        {name}
      </div>
      {items.map((item) => (
        <PlaylistItem key={`${item.id}_${item.position}`} {...item} />
      ))}
    </div>
  );
}

export default Playlist;