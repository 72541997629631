import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MainFrame from "../main-frame";
import { apiUrl } from '../../util/env';
import Loader from "../loader";
import ErrorPanel from "../error-panel";
import Playlist from "../playlist";
import Image from "../image";
import Video from "../video";
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_PLAYLIST, MEDIA_TYPE_VIDEO } from "../../util/media-types";
import { CircularGeofenceRegion } from "../../util/circular-geofencing";

const url = new URL(window.location.href);
const qPlayerId = url.searchParams.get('pid');
const qKey = url.searchParams.get('__key') || '';

function Main() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [playerId, setPlayerId] = useState();
  const [result, setResult] = useState();


  useEffect(() => {
    if (!qPlayerId) {
      setError(new Error('Invalid URL'));
      setLoading(false);
      return;
    }
    setPlayerId(qPlayerId);

    window.gtag('event', 'qrcode_view', {
      player_id: qPlayerId,
    });
  }, []);

  useEffect(() => {
    if (playerId) {
      (async () => {
        const loadData = async () => {
          try {
            const response = await fetch(`${apiUrl}/players/${playerId}/config?__key=${qKey}`);
            if (!response.ok) {
              const responseBody = await response.json();

              const {
                error: {
                  payload: {
                    projectId,
                  } = {},
                } = {},
              } = responseBody;

              const message = projectId ? 'No content!' : 'Ooops! Error loading data :( Please try again later...';
              const error = new Error(message);
              error.projectId = projectId;

              throw error;
            }
            const jsonResult = await response.json();
            setResult(jsonResult);

            const {
              projectId,
              lat,
              lon,
              radius,
              mediaId,
              type,
              url,
              openingType,
            } = jsonResult;

            if (radius) {
              try {
                const fence = new CircularGeofenceRegion({
                  name: 'qrcode-fence',
                  latitude: parseFloat(lat),
                  longitude: parseFloat(lon),
                  radius,
                });

                await new Promise((resolve, reject) => {
                  navigator.geolocation.getCurrentPosition((position) => {
                    if (fence.inside(position.coords.latitude, position.coords.longitude)) {
                      console.log('accessing QR inside fence');
                      resolve('ok');
                    } else {
                      console.log('accessing QR outside fence');
                      const error = new Error('Too far from QR Code location.');
                      error.projectId = projectId;
                      reject(error);
                    }
                  }, (error) => {
                    reject(error);
                  }, {
                    enableHighAccuracy: true,
                  });
                });
              } catch (e) {
                throw e;
              }
            }

            window.gtag('event', 'qrcode_content', {
              project_id: projectId,
              player_id: qPlayerId,
              media_id: mediaId,
              media_type: type,
              url: url,
              opening_type: openingType,
            });
          } catch (e) {
            setError(e);
            const { projectId } = e;

            if (projectId) {
              window.gtag('event', 'qrcode_no_content', {
                project_id: projectId,
                player_id: qPlayerId,
              });
            } else {
              window.gtag('event', 'qrcode_error', {
                player_id: qPlayerId,
                message: e.message,
              });
            }
          }
        };
        const wait = (delay) => new Promise((resolve) => {
          setTimeout(resolve, delay);
        });

        await Promise.all([loadData(), wait(100)]);
        setLoading(false);
      })();
    }
  }, [playerId]);

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <ErrorPanel error={error} />
    );
  }

  const {
    url: urlAddress,
    title,
    dataKey,
    openingType,
    type,
    items,
    files,
  } = result;

  let url;
  if (urlAddress) {
    url = new URL(urlAddress);
    const params = new URLSearchParams(url.search);
    if (dataKey) {
      params.set('__key', dataKey);
    }
    url.search = params.toString();
  }


  if (openingType === 'redirect') {
    window.location = url.href;
    return null;
  }

  let content = null;

  if (openingType === 'iframe') {
    content = (
      <MainFrame
        url={url.href}
        title={title}
      />
    )
  } else if (openingType === 'local') {
    if (type === MEDIA_TYPE_PLAYLIST) {
      content = (
        <Playlist name={title} items={items} />
      );
    } else if (type === MEDIA_TYPE_IMAGE) {
      content = (
        <Image urls={files} />
      );
    } else if (type === MEDIA_TYPE_VIDEO) {
      content = (
        <Video urls={files} />
      );
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {content}
    </>
  );
}

export default Main;