import React from 'react';
import qrlogo from '../../assets/images/qr-code.png';
import './style.sass';

function Loader() {
  return (
    <div className="loader">
      <img
        className="logo bounce"
        src={qrlogo}
        alt="loading..."
      />
    </div>
  );
}

export default Loader;
