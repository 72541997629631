export class CircularGeofenceRegion {
  constructor(opts) {
    Object.assign(this, opts);
  }

  inside(lat2, lon2) {
    const lat1 = this.latitude;
    const lon1 = this.longitude;
    const R = 63710; // Earth's radius in m

    return (
      Math.acos(
        Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
      ) *
        R <
      this.radius
    );
  }
}
