import React, { useLayoutEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import './style.sass';

function Video({
  urls = [],
}) {
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useLayoutEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  const handleEnd = () => {
    setIndex((index + 1) % urls.length);
  };

  const url = urls[index];

  if (!width) return null;

  return (
    <ReactPlayer
      url={url}
      onEnded={() => handleEnd()}
      muted
      controls
      width={width}
      height={height}
    />
  );
}

export default Video;
