import React from 'react';
import './style.sass';

function ErrorPanel({
  error,
}) {
  return (
    <div className="error">
      {error.message}
    </div>
  );
}

export default ErrorPanel;
