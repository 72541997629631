import './app.sass';
import Main from './components/main';

function App() {
  return (
    <div className="app">
      <Main/>
    </div>
  );
}

export default App;
